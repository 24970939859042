<template>
    <header
        class="sticky top-0 z-40 flex h-auto shrink-0">
        <div class="flex grow max-w-[1700px] mx-auto px-4 sm:gap-x-6 sm:px-6 lg:px-8 py-6">
            <div class="flex grow flex-col xs:flex-row justify-between gap-8 bg-white dark:bg-gray-900">
                <NuxtLink :to="localePath('/')">
                    <LogoImage />
                </NuxtLink>

                <div class="flex gap-8">
                    <div class="flex flex-col items-end text-end">
                        <h2>
                            <CountUp
                                :end-val="configurator.subtotal"
                                :start-val="configurator.oldSubtotal"
                                duration="0.5"
                                class="w-fit"
                                decimal-places="2"
                                :options="{'separator':'.','decimal':',','suffix':'&nbsp;€'}" />
                        </h2>
                        <small>{{ $t('pricePreview') }}</small>
                    </div>
                    <div class="flex grow items-center justify-end gap-4 ps-2">
                        <BasePopover
                            size="sm">
                            <template #trigger>
                                <button
                                    class="px-2 py-1 bg-transparent rounded-md"
                                    @click="showLoadTokenModal=true">
                                    <Icon
                                        name="material-symbols:upload-2"
                                        class="h-6 w-6 text-gray-900" />
                                </button>
                            </template>
                            <template #default>
                                {{ $t('configurator.loadToken.load') }}
                            </template>
                        </BasePopover>
                        <BasePopover
                            size="sm">
                            <template #trigger>
                                <button
                                    class="px-2 py-1 bg-transparent rounded-md"
                                    @click="saveConfiguration()">
                                    <LoadingSpinner
                                        v-if="saving"
                                        class="h-6 w-6 text-gray-900" />
                                    <Icon
                                        v-else
                                        name="ion:ios-save"
                                        class="h-6 w-6 text-gray-900" />
                                </button>
                            </template>
                            <template #default>
                                {{ $t('configurator.saveToken.save') }}
                            </template>
                        </BasePopover>
                    </div>
                </div>
            </div>
        </div>

        <BaseModal v-model="showSaveTokenModal">
            <div class="px-12 py-8">
                <h1 class="mb-8">
                    {{ $t('configurator.saveToken.title') }}
                </h1>
                <p class="mb-4">
                    {{ $t('configurator.saveToken.description') }}
                </p>
                <div class="flex items-center gap-5">
                    <div class="h-full grow">
                        <div class="flex items-center border border-primary bg-primary rounded-md overflow-hidden">
                            <p class="bg-white rounded-md px-3 py-2 flex-grow">
                                {{ savedToken }}
                            </p>
                            <button
                                class="bg-primary px-2 py-1 rounded-md"
                                @click="copyToken()">
                                <Icon
                                    name="material-symbols:content-copy"
                                    class="h-6 w-6 text-white" />
                            </button>
                        </div>
                    </div>

                    <div class="h-full">
                        <button
                            class="bg-primary px-2 py-2 rounded-md"
                            @click="printConfiguration()">
                            <Icon
                                name="carbon:generate-pdf"
                                class="h-6 w-6 text-white" />
                        </button>
                    </div>
                </div>
            </div>
        </BaseModal>
        <BaseModal v-model="showLoadTokenModal">
            <div class="px-12 py-8">
                <h1 class="mb-8">
                    {{ $t('configurator.loadToken.title') }}
                </h1>
                <p class="mb-4">
                    {{ $t('configurator.loadToken.description') }}
                </p>
                <div class="flex items-center border border-primary bg-primary rounded-md overflow-hidden">
                    <BaseInput
                        v-model="loadToken"
                        :placeholder="$t('configurator.loadToken.token')" />

                    <button
                        class="bg-primary px-2 py-1 rounded-md"
                        @click="loadConfiguration()">
                        <Icon
                            name="material-symbols:upload-2"
                            class="h-5 w-5 text-white" />
                    </button>
                </div>
            </div>
        </BaseModal>
    </header>
</template>

<script setup>
import {useNotification} from '@/composables/useNotification'
import {useCopyClipboard} from '@/composables/clipboard'
import {useI18n} from 'vue-i18n'
import {useConfigurator} from '@/store/configurator'
import CountUp from 'vue-countup-v3'
import {useNuxtApp} from "nuxt/app";

const {t} = useI18n()
const notification = useNotification()

const configurator = useConfigurator()
const loadToken = ref('')
const savedToken = ref(null)
const showLoadTokenModal = ref(false)
const saving = ref(false)

const showSaveTokenModal = computed({
    get: () => !!savedToken.value,
    set: (value) => {
        if (!value) {
            savedToken.value = null
        }
    }
})

function loadConfiguration() {
    configurator.loadConfiguration(loadToken.value)
    showLoadTokenModal.value = false
}

async function saveConfiguration() {
    saving.value = true
    const savedConfiguration = await configurator.saveConfiguration()
    if (savedConfiguration) {
        saving.value = false
        showSaveTokenModal.value = true
        savedToken.value = savedConfiguration.token
    } else {
        saving.value = false
    }
}

function copyToken() {
    if (savedToken.value) {
        useCopyClipboard(savedToken.value).then(() => {
            notification.success(t('configurator.saveToken.copyTokenSuccess'))
        })
    }
}

function printConfiguration() {
    configurator.printConfiguration(savedToken.value)
}
</script>

<style scoped>

</style>